.bottom-contact--container{
    width: 100%;
    height: 100%;
    background-color: #161619;
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    
    display: flex;
}

.bottom-contact--scmedia{
    width: 30px;
    height: 30px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: 20px;

    border: none;

    margin-right: 12px;
    margin-left: 12px;

    background-color: #918E9B;
    border-radius: 10%;
}

.bottom-contact--scmedia:hover{
    background-color: rgb(142, 148, 155);
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.03);
}

.bottom-contact--mid{
    width: 100%;
    height: 100%;

    /* margin-left: -50px; */

    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}