.main-content--container{
    width: 100%;
    height: 100%;
    padding-left: 20px;
    color: #fff;
}

.main-content--title{
    font-size: 20px;
    font-weight: bold;
}

.main-content--text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
    max-width: 270px;
    line-height: 150%;
}