body {
    background-color: #23252C;
    font-family: Inter, sans-serif;
}

.card--container {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    
    margin: auto;

    height: fit-content;
    width: fit-content;
    /* padding-bottom: 10px; */
    margin-top: 50px;
    margin-bottom: 50px;
    /* padding: 10px; */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    background-color: #1A1B21;
    border-radius: 10px;
    
  }
  
  /* On mouse-over, add a deeper shadow */
  .card--container:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    /* transform: scale(1.001); */
  }

a, a:visited, a:active {
  color: inherit;
  text-decoration: none;
}
