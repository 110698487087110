.top-contact--title{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 25px;
}

.top-contact--main{
    text-align: center;
    line-height: 50%;
}

.top-contact--subtitle{
    background: -webkit-linear-gradient(150deg, rgb(0, 228, 68) 20%, rgba(0,212,255,1) 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
    font-size: 12.8px;
    padding: 2px;
    padding-bottom: 3px;
}

.top-contact--inline{
    display: flex;
    color: #fff
}

.button-blurple{
    background-color: #212d77;
    margin-left: 20px;
    margin-right: auto;
    float: left;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.button-blurple, .button-red {
    border-radius: 4px;

    padding: 15px;

    color: #FFFFFF;
    font-weight: 700;
    font-size: 12.8px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

.button-blurple:hover{
    background-color: rgb(27, 34, 68);
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.03);
}

.button-red{
    background-color: #FF0000;
    margin-left: auto;
    margin-right: 20px;
    float: right;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.button-red:hover{
    background-color: #9c1313;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.03);
}